import { Container } from "@mui/material";
import { Box } from "@mui/system";

import HomePageTitle from "./Title/HomePageTitle";
import { useEffect, useState } from "react";
import { fetchBrands } from "services/brandsService";

const Home = () => {
  useEffect(() => {
    // (async () => {
    //   const { data } = await fetchBrands();
    //   setBrands(data);
    // })();
  }, []);

  useEffect(() => {
    (async () => {
      // const { data } = await fetchUpcomingCollections();
      // if (data.length === 0) {
      //   const { data: latest } = await fetchLatestCollection();
      //   setLatestCollection(latest);
      //   setUpcomingCollections([]);
      // } else {
      //   setUpcomingCollections(data);
      // }
    })();
  }, []);

  return (
    <Box
      sx={{
        mt: { xs: "80px" },
      }}
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      data-testid="homepage-wrapper"
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        maxWidth="xl"
      >
        <HomePageTitle />
      </Container>

      {/*
      <Box
        position="relative"
        width="100%"
        zIndex={2}
        data-testid="homepage-upcoming-drops-wrapper"
      >
        <UpcomingDrop collections={upcomingCollections} latest={latestCollection} />
      </Box>
      */}

      <Box
        width="100%"
        display="flex"
        alignItems="center"
        flexDirection="column"
        data-testid="homepage-brand-collection-wrapper"
      >
        {/* <BrandCollection /> */}
      </Box>
    </Box>
  );
};

export default Home;
