import { ethers } from "ethers";

export const isEthereumPublicKey = (publicKey: string) => {
  if (ethers.utils.isAddress(publicKey)) {
    return true;
  }
  return false;
};

export const isEthereumPrivateKey = (privateKey: string) => {
  try {
    const ethWallet = new ethers.Wallet(privateKey);
    return true;
  } catch (err) {
    return false;
  }
};

export const getPublicKeyFromPrivateKey = (
  privateKey: string,
  pubicKey: string
) => {
  try {
    const wallet = new ethers.Wallet(privateKey);
  } catch (err) {
    return false;
  }
};
