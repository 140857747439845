import { Typography } from "@mui/material";

const NotFound = () => {
  return (
    <Typography variant="h1" color="white.main">
      The page you are looking for is Not found!
      <br />
      <div className="box-not-found">
        <img src="/images/404-pulp-fiction-john-travolta.gif"></img>
      </div>
    </Typography>
  );
};

export default NotFound;
