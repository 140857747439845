import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactComponent as GettingStartedIcon } from "assets/icons/getting-started.svg";
import { ReactComponent as RightArrow } from "assets/icons/right-arrow.svg";
import { ReactComponent as SmallRightArrow } from "assets/icons/arrow-right-small.svg";
import { ReactComponent as HelpIcon } from "assets/icons/help-user-support.svg";
import { ReactComponent as BulbIcon } from "assets/icons/answer-bulb.svg";
import { ReactComponent as OpenCollapse } from "assets/icons/open-collapse.svg";
import { ReactComponent as CloseCollapse } from "assets/icons/close-collapse.svg";
import BackLink from "components/UI/BackLink";
import BreadcrumbsNavigation from "components/UI/BreadcrumbsNavigation";
import Divider from "components/UI/Divider";
import { useState } from "react";
import Answer from "./Answer";
import {
  Question,
  QUESTIONS,
  QUESTION_CATEGORIES,
  TOP_QUESTIONS,
} from "./data";
interface IQuestionItemProps {
  onClick: () => void;
  question: string;
}

export const QuestionsItem = ({ onClick, question }: IQuestionItemProps) => (
  <Box
    py="24px"
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    color="white.main"
    onClick={onClick}
    sx={{
      cursor: "pointer",
      borderBottom: "1px solid",
      borderColor: "border.inner",
      "&:hover": {
        "& span": {
          color: "lightBorealBlue.40",
        },
        "& svg path": {
          stroke: (theme) => theme.palette.lightBorealBlue[40],
        },
      },
      "&:last-of-type": {
        borderBottom: 0,
      },
    }}
  >
    <Typography variant="body16">{question}</Typography>
    <SmallRightArrow />
  </Box>
);

interface ICategoryQuestionsProps {
  handler: (answerId: number) => void;
  questions: Question[];
}

export const CategoryQuestions = ({
  handler,
  questions,
}: ICategoryQuestionsProps) => {
  const handleQuestionClick = (answerId: number) => {
    handler(answerId);
  };
  return (
    <Box>
      {questions.length > 0 ? (
        questions.map(({ question, questionId, answerId }) => (
          <QuestionsItem
            key={`category-questions-item-${questionId}`}
            question={question}
            onClick={() => handleQuestionClick(answerId)}
          />
        ))
      ) : (
        <Typography variant="body16" color="inherit" sx={{ mt: "24px" }}>
          No questions for selected category
        </Typography>
      )}
    </Box>
  );
};

interface ITitleBlueRectangleProps {
  mb: string;
  mt?: string;
}

const TitleBlueRectangle = ({ mb, mt = "18px" }: ITitleBlueRectangleProps) => (
  <Box
    bgcolor="lightBorealBlue.main"
    width="30px"
    height="3px"
    borderRadius="3px"
    mt={mt}
    mb={mb}
  />
);

interface ICategoryBoxProps {
  title: string;
  selected?: boolean;
  onClick: () => void;
}

const CategoryBox = ({
  title,
  selected = false,
  onClick,
}: ICategoryBoxProps) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    gap="16px"
    border="1px solid"
    position="relative"
    onClick={onClick}
    sx={{
      p: { xs: "16px", lg: "12px 20px" },
      cursor: "pointer",
      borderColor: selected ? "lightBorealBlue.main" : "border.inner",
      height: { xs: "56px", lg: "80px" },
      minWidth: { xs: "fit-content", lg: 0 },
      "& svg": {
        display: { xs: "none", lg: "block" },
      },
      ...(!selected && {
        "&:hover": {
          borderColor: "lightBorealBlue.40",
        },
      }),
    }}
    borderRadius="8px"
  >
    <BulbIcon width="30px" height="30px" />
    <Typography variant="h5" color="white.main">
      {title}
    </Typography>
    {selected && (
      <Box
        left="0"
        position="absolute"
        width="3px"
        height="30px"
        borderRadius="0 10px 10px 0"
        bgcolor="lightBorealBlue.main"
      />
    )}
  </Box>
);

const UserSupport = () => {
  const [selectedAnswer, setSelectedAnswer] = useState<number>();
  const [selectedCategory, setSelectedCategory] = useState<number>(1);
  const [openTopQuestions, setOpetTopQuestions] = useState<boolean>(false);
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const handleCategoryClick = (categoryId: number) => {
    setSelectedCategory(categoryId);
    setSelectedAnswer(undefined);
  };

  return (
    <Box width="100%" position="relative" mb="auto">
      <Box
        position="absolute"
        sx={{
          top: { xs: "10px", md: "12px", top: "40px" },
          left: { xs: "20px", md: "21px", top: "40px" },
        }}
      >
        {downLg ? (
          <BackLink to="/" text="Home" light />
        ) : (
          <BreadcrumbsNavigation
            breadcrumbs={[{ text: "User Support" }]}
            light
          />
        )}
      </Box>
      <Box
        bgcolor="primary.80"
        width="100%"
        sx={{
          height: { xs: "60px", lg: "100px" },
        }}
      />
      <Grid
        container
        justifyContent="center"
        columnSpacing="21px"
        sx={{
          mt: { xs: "-20px", lg: "-50px" },
          px: { xs: "20px", lg: 0 },
        }}
      >
        <Grid item xs={12} lg={3}>
          <Box borderRadius="10px" bgcolor="background.light">
            <Box
              alignItems="center"
              gap="16px"
              sx={{
                p: { xs: "20px", lg: "30px", xl: "50px" },
                display: { xs: "flex", lg: "block" },
              }}
            >
              <HelpIcon />
              <Typography
                color="white.main"
                variant="h2"
                sx={{ mt: { lg: "30px" } }}
              >
                User Support
              </Typography>
            </Box>
            <Divider
              sx={{
                borderColor: "background.dark",
                borderWidth: "2px",
                mt: { lg: "10px" },
              }}
              horizontal
            />
            <Box
              sx={{
                p: { xs: "20px", lg: "30px", xl: "50px" },
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography color="white.main" variant="h3">
                  Top questions
                </Typography>
                <IconButton
                  sx={{ display: { lg: "none" } }}
                  onClick={() => setOpetTopQuestions((prev) => !prev)}
                >
                  {openTopQuestions ? <CloseCollapse /> : <OpenCollapse />}
                </IconButton>
              </Box>

              <Box sx={{ display: { lg: "none" } }}>
                <Collapse in={openTopQuestions}>
                  <TitleBlueRectangle mb="16px" />
                  {TOP_QUESTIONS.map((questionId) => (
                    <QuestionsItem
                      key={`top-questions-item-${questionId}`}
                      question={
                        QUESTIONS.find((q) => q.questionId === questionId)
                          ?.question || ""
                      }
                      onClick={() => setSelectedAnswer(questionId)}
                    />
                  ))}
                </Collapse>
              </Box>
              <Box sx={{ display: { xs: "none", lg: "block" } }}>
                <TitleBlueRectangle mb="16px" />
                {TOP_QUESTIONS.map((questionId) => (
                  <QuestionsItem
                    key={`top-questions-item-${questionId}`}
                    question={
                      QUESTIONS.find((q) => q.questionId === questionId)
                        ?.question || ""
                    }
                    onClick={() => setSelectedAnswer(questionId)}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Box
            bgcolor="lightBorealBlue.60"
            borderRadius="10px"
            overflow="hidden"
            position="relative"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              p: {
                xs: "20px 20px 20px 44px",
                lg: "30px 30px 30px 80px",
                xl: "50px 50px 50px 117px",
              },
              mb: "20px",
              mt: { xs: "20px", lg: 0 },
            }}
          >
            <Box
              position="absolute"
              top="50%"
              left="0"
              sx={{
                transform: {
                  xs: "translateY(-50%) scale(0.4)",
                  lg: "translateY(-50%) scale(0.7)",
                  xl: "translateY(-50%)",
                },
                "& svg path": {
                  strokeWidth: { xs: "3px", lg: "2px", xl: "1.5px" },
                },
                transformOrigin: "left",
              }}
            >
              <GettingStartedIcon />
            </Box>
            <Typography color="black.main" variant="h3">
              Get started here
            </Typography>
            <Box
              sx={{
                "& svg path": {
                  fill: (theme) => theme.palette.black.main,
                },
              }}
            >
              <RightArrow height="24px" width="24px" />
            </Box>
          </Box>
          <Box
            borderRadius="10px"
            bgcolor="background.light"
            color="white.main"
            sx={{
              p: { xs: "20px", lg: "30px", xl: "50px" },
            }}
          >
            {selectedAnswer ? (
              <Answer
                answerId={`cat${selectedCategory}-a${selectedAnswer}`}
                question={
                  QUESTIONS.find((q) => q.answerId === selectedAnswer)
                    ?.question || ""
                }
                goBack={() => setSelectedAnswer(undefined)}
              />
            ) : (
              <Box>
                <Typography color="white.main" variant="h3">
                  Find your answer
                </Typography>
                <TitleBlueRectangle mb="40px" />
                <Box
                  display="flex"
                  alignItems="center"
                  gap="10px"
                  className="custom-scroll"
                  sx={{
                    flexWrap: { xs: "nowrap", lg: "wrap" },
                    overflowX: { xs: "auto", lg: "unset" },
                    overflowY: "hidden",
                    pb: { xs: "10px", lg: 0 },
                  }}
                >
                  {QUESTION_CATEGORIES.map(({ categoryId, title }) => (
                    <CategoryBox
                      key={`answer-box-${categoryId}`}
                      title={title}
                      selected={selectedCategory === categoryId}
                      onClick={() => handleCategoryClick(categoryId)}
                    />
                  ))}
                </Box>
                <Box mt="24px" color="white.main">
                  <CategoryQuestions
                    questions={QUESTIONS.filter(
                      (q) => q.categoryId === selectedCategory
                    )}
                    handler={setSelectedAnswer}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserSupport;
