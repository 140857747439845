import { Box, Typography } from "@mui/material";
import MetamaskLogo from "assets/images/metamask-logo.png";
import PalmLogo from "assets/icons/network-palm.png";
import { ReactComponent as EthLogo } from "assets/icons/eth.svg";
import { ReactComponent as Polygon } from "assets/icons/polygon.svg";
import { PALM_MAINNET, POLYGON_MAINNET } from "utils/networks";
import { Link } from "react-router-dom";

const LinksUserSupportTermsPolicy = () => {
  return (
    <>
      <Box
        display="flex"
        color="white.main"
        sx={{
          order: { xs: 3, lg: 3 },
          justifyContent: { xs: "center", md: "flex-end" },
        }}
        gap="20px"
      >
        <Link to="/terms-and-conditions">
          <Typography
            textAlign="center"
            variant="body12"
            color="greyBlue.light"
            sx={{ textDecoration: "underline" }}
          >
            Terms & Conditions
          </Typography>
        </Link>
        <Link to="/user-support">
          <Typography
            textAlign="center"
            variant="body12"
            color="greyBlue.light"
            sx={{ textDecoration: "underline" }}
          >
            User Support
          </Typography>
        </Link>
      </Box>
    </>
  );
};

export default LinksUserSupportTermsPolicy;
