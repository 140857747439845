import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import BackLink from "components/UI/BackLink";
import BreadcrumbsNavigation from "components/UI/BreadcrumbsNavigation";
import Divider from "components/UI/Divider";
import { useState } from "react";
import UserTerms from "./UserTerms";
import PrivacyPolicy from "./PrivacyPolicy";

interface ITabItemProps {
  onClick: () => void;
  selected: boolean;
  text: string;
}

const TabItem = ({ onClick, selected, text }: ITabItemProps) => (
  <Box
    position="relative"
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="100%"
    onClick={onClick}
    color="white.main"
    sx={{
      cursor: "pointer",
      ...(!selected && {
        "&:hover": {
          color: "lightBorealBlue.40",
        },
      }),
    }}
  >
    <Typography color="inherit" variant="body16" textAlign="center">
      {text}
    </Typography>

    {selected && (
      <Box
        bottom="0"
        position="absolute"
        width="50px"
        height="4px"
        borderRadius="10px 10px 0 0"
        bgcolor="lightBorealBlue.main"
      />
    )}
  </Box>
);

const TermsAndConditions = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabSelectClick = (index: number) => {
    if (index > 1) {
      setSelectedTab(1);
    }
    if (index < 0) {
      setSelectedTab(0);
    }
    setSelectedTab(index);
  };

  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Box width="100%" position="relative" mb="auto">
      <Box
        position="absolute"
        sx={{
          top: { xs: "10px", md: "12px", top: "40px" },
          left: { xs: "20px", md: "21px", top: "40px" },
        }}
      >
        {downLg ? (
          <BackLink to="/" text="Home" light />
        ) : (
          <BreadcrumbsNavigation
            breadcrumbs={[{ text: "Terms & Conditions" }]}
            light
          />
        )}
      </Box>
      <Box
        bgcolor="primary.80"
        width="100%"
        sx={{
          height: { xs: "60px", md: "100px" },
        }}
      />

      <Grid
        container
        justifyContent="center"
        sx={{
          mt: { xs: "-30px", lg: "-50px" },
          px: { xs: "20px", lg: 0 },
        }}
      >
        <Grid item xs={11} md={10}>
          <Box borderRadius="10px" bgcolor="background.light">
            <Box
              sx={{
                p: { xs: "20px", md: "30px", xl: "50px" },
              }}
            >
              <Typography color="white.main" variant="h1" textAlign="center">
                Terms & Conditions
              </Typography>
            </Box>
            <Divider
              sx={{
                borderColor: "background.dark",
                borderWidth: "2px",
                mt: { xs: 0, md: "10px" },
              }}
              horizontal
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                height: { xs: "64px", md: "82px" },
                gap: { xs: "50px", md: "70px" },
              }}
            >
              <TabItem
                text="User Terms"
                selected={selectedTab === 0}
                onClick={() => handleTabSelectClick(0)}
              />
              <TabItem
                text="Privacy Policy"
                selected={selectedTab === 1}
                onClick={() => handleTabSelectClick(1)}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={11} md={10} mt="20px">
          <Box
            borderRadius="10px"
            bgcolor="background.light"
            sx={{
              p: { xs: "20px", md: "50px", xl: "80px" },
            }}
          >
            {selectedTab === 0 && <UserTerms />}
            {selectedTab === 1 && <PrivacyPolicy />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TermsAndConditions;
