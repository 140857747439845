import { Link, Typography } from "@mui/material";
import { Box } from "@mui/system";

const PrivacyPolicy = () => (
  <Box
    color="white.main"
    display="flex"
    flexDirection="column"
    gap="30px"
    sx={{ gap: { xs: "15px", md: "20px", xl: "30px" } }}
  >
    <Typography variant="h3" color="inherit" sx={{ mb: { xs: 0, md: "10px" } }}>
      Protecting Your Privacy
    </Typography>
    <Typography variant="body14" color="inherit">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vitae
      suscipit leo, euismod blandit est. Pellentesque habitant morbi tristique
      senectus et netus et malesuada fames ac turpis egestas. Fusce ac nisi
      vitae turpis fringilla dignissim. Pellentesque vel eros eu mauris suscipit
      vulputate. Etiam nec velit ac diam rutrum euismod. Praesent congue
      ultricies lacinia. Vestibulum non ante tincidunt, vehicula nisl vel,
      rutrum mi. Sed magna purus, ultrices nec turpis ut, elementum ultrices
      est. Integer sollicitudin diam metus, non eleifend elit pellentesque at.
      Maecenas cursus tempus enim id tristique
    </Typography>
    <Link href="www.polygon.com">
      <Typography variant="body14" color="lightBorealBlue.main">
        www.polygon.com
      </Typography>
    </Link>
    <Typography variant="body14" color="inherit">
      Sed magna purus, ultrices nec turpis ut, elementum ultrices est. Integer
      sollicitudin diam metus, non eleifend elit pellentesque at. Maecenas
      cursus tempus enim id tristique. Sed magna purus, ultrices nec turpis ut,
      elementum ultrices est. Integer sollicitudin diam metus, non eleifend elit
      pellentesque at. Maecenas cursus tempus enim id tristique
    </Typography>
    <Typography variant="body14" color="inherit">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vitae
      suscipit leo, euismod blandit est. Pellentesque habitant morbi tristique
      senectus et netus et malesuada fames ac turpis egestas. Fusce ac nisi
      vitae turpis fringilla dignissim. Pellentesque vel eros eu mauris suscipit
      vulputate. Etiam nec velit ac diam rutrum euismod. Praesent congue
      ultricies lacinia. Vestibulum non ante tincidunt, vehicula nisl vel,
      rutrum mi. Sed magna purus, ultrices nec turpis ut, elementum ultrices
      est. Integer sollicitudin diam metus, non eleifend elit pellentesque at.
      Maecenas cursus tempus enim id tristique
    </Typography>
    <Typography variant="body14" color="inherit">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vitae
      suscipit leo, euismod blandit est. Pellentesque habitant morbi tristique
      senectus et netus et malesuada fames ac turpis egestas. Fusce ac nisi
      vitae turpis fringilla dignissim. Pellentesque vel eros eu mauris suscipit
      vulputate. Etiam nec velit ac diam rutrum euismod. Praesent congue
      ultricies lacinia. Vestibulum non ante tincidunt, vehicula nisl vel,
      rutrum mi. Sed magna purus, ultrices nec turpis ut, elementum ultrices
      est. Integer sollicitudin diam metus, non eleifend elit pellentesque at.
      Maecenas cursus tempus enim id tristique
    </Typography>
    <Typography variant="h3" color="inherit" sx={{ my: { xs: 0, md: "10px" } }}>
      Lorem ipsum title 2
    </Typography>
    <Typography variant="body14" color="inherit">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vitae
      suscipit leo, euismod blandit est. Pellentesque habitant morbi tristique
      senectus et netus et malesuada fames ac turpis egestas. Fusce ac nisi
      vitae turpis fringilla dignissim. Pellentesque vel eros eu mauris suscipit
      vulputate. Etiam nec velit ac diam rutrum euismod. Praesent congue
      ultricies lacinia. Vestibulum non ante tincidunt, vehicula nisl vel,
      rutrum mi. Sed magna purus, ultrices nec turpis ut, elementum ultrices
      est. Integer sollicitudin diam metus, non eleifend elit pellentesque at.
      Maecenas cursus tempus enim id tristique
    </Typography>
    <Link href="www.polygon.com">
      <Typography variant="body14" color="lightBorealBlue.main">
        www.polygon.com
      </Typography>
    </Link>
    <Typography variant="body14" color="inherit">
      Sed magna purus, ultrices nec turpis ut, elementum ultrices est. Integer
      sollicitudin diam metus, non eleifend elit pellentesque at. Maecenas
      cursus tempus enim id tristique. Sed magna purus, ultrices nec turpis ut,
      elementum ultrices est. Integer sollicitudin diam metus, non eleifend elit
      pellentesque at. Maecenas cursus tempus enim id tristique
    </Typography>
  </Box>
);

export default PrivacyPolicy;
