import { Box } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import About from "./views/About";
import Home from "./views/HomePage";
import NotFound from "views/NotFound";
import ScrollToTop from "utils/ScrollToTop";
import { RestrictedAreaPopup } from "components/Dialogs/CountryBlockedPopup";
import AppProviders from "AppProviders";
import UserSupport from "views/UserSupport";
import TermsAndConditions from "views/TermsAndConditions";
import Airdrop0 from "views/Airdrop/Airdrop0";
import Airdrop1 from "views/Airdrop/Airdrop1";
import Claim from "views/Claim/Claim";

declare global {
  interface Window {
    ethereum?: any;
  }
}

export const App = () => {
  return (
    <AppProviders>
      <Box
        sx={{ backgroundColor: "background.black" }}
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
      >
        <BrowserRouter>
          <Header connected={true} />
          <ScrollToTop>
            <Routes>
              <Route path="/airdrop0/:smartcontractId" element={<Airdrop0 />} />
              <Route path="/air-drop/:walletAdrs" element={<Airdrop1 />} />
              <Route path="/claim/:walletAdrs" element={<Claim />} />

              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/user-support" element={<UserSupport />} />
              <Route path="/not-found" element={<NotFound />} />
              <Route path="/home" element={<Home />} />
              <Route path="/" element={<Home />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ScrollToTop>
          <Footer />
          <RestrictedAreaPopup />
        </BrowserRouter>
      </Box>
    </AppProviders>
  );
};

export default App;
