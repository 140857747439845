import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

const SmartContractLink = ({
  blockchainId,
  adress,
}: {
  blockchainId?: number;
  adress?: string;
}) => {
  console.log("BC ID : ", blockchainId, "typeof bcId", typeof blockchainId);
  return (
    <Box>
      {blockchainId === 1 && (
        <Button
          href={`https://polygonscan.com/address/${adress}`}
          target="_blank"
          variant="outlined"
          style={{ marginTop: "10px", justifyContent: "center" }}
        >
          Polygon
        </Button>
      )}
      {blockchainId === 2 && (
        <Button
          href={`https://mumbai.polygonscan.com/address/${adress}`}
          target="_blank"
          variant="outlined"
          style={{ marginTop: "10px", justifyContent: "center" }}
        >
          Polygon Mumbai
        </Button>
      )}
      {blockchainId === 3 && (
        <Button
          href={`https://goerli.etherscan.io/address/${adress}`}
          target="_blank"
          variant="outlined"
          style={{ marginTop: "10px", justifyContent: "center" }}
        >
          Goerli
        </Button>
      )}
      {blockchainId === 4 && (
        <Button
          href={`https://etherscan.com/address/${adress}`}
          target="_blank"
          variant="outlined"
          style={{ marginTop: "10px", justifyContent: "center" }}
        >
          Ethereum
        </Button>
      )}
    </Box>
  );
};

export default SmartContractLink;
