import { ethers } from "ethers";

export const connectMetaMask = async () =>{
    let res: any;
    if (typeof window.ethereum != "undefined"){
        const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
        })
        
        console.log('accounts',accounts);
        res = {
            status: "You are connected in the metamask",
            address: accounts[0],
        }
    }else{
        res = {
            address: "",
            status: "You must install Metamask, a virtual Ethereum wallet, in your browser."
        };
    }
    return res
}

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: "👆🏽 Connected.",
          };
        } else {
          return {
            address: "",
            status:
              "🦊 Login to Metamask",
          };
        }
      } catch (err: any) {
        return {
          address: "",
          status: "😥 " + err.message,
        };
      }
    } else {
      return {
        address: "",
        status: "Install metamask",
      };
    }
  };