import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as DiscordIcon } from "assets/icons/discord-black.svg";

const HomePageTitle = () => {
  return (
    <Box
      data-testid="homepage-title-wrapper"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        textAlign="center"
        variant="h1"
        color="white.main"
        sx={{
          maxWidth: {
            xs: "280px",
            sm: "300px",
            ml: "340px",
            md: "640px",
            lg: "640px",
          },
          fontWeight: 700,
        }}
        data-testid="homepage-title-title"
      >
        Multi Token Viewer
      </Typography>
      <Typography
        textAlign="center"
        variant="body14"
        color="white.main"
        sx={{
          maxWidth: { xs: "280px", sm: "80%", md: "550px", lg: "760px" },
          my: "20px",
        }}
        data-testid="homepage-title-sub-title"
      >
        Welcome to the Multi Token Viewer ( M.T.V ) Aura Service. Claim.
      </Typography>
      <Button
        variant="outlined"
        color="greyBlue"
        startIcon={<DiscordIcon />}
        data-testid="homepage-title-join-community-button"
        onClick={() =>
          window.open("https://discord.com/invite/XcykqZt4fh", "_blank")
        }
      >
        Join Community
      </Button>
    </Box>
  );
};

export default HomePageTitle;
