import { Grid, Typography, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import AuraBcLogo from "assets/logos/aura-white.svg";
import LinksSocialNetworks from "./LinksSocialNetworks";

import LinksUserSupportTermsPolicy from "./LinksUserSupportTermsPolicy";
import Newsletter from "./Newsletter";

const Footer = () => {
  return (
    <>
      <Box
        component="footer"
        width="100%"
        sx={{
          bgcolor: "black.100",
          height: { xs: "100%", xl: "200px" },
          maxHeight: { xs: "500px" },
          mt: { xs: "45px", md: "75px", lg: "120px" },
          p: { xs: "40px 10px", lg: "35px 60px", xl: "60px 150px" },
        }}
        data-testid="footer-wrapper"
      >
        <Grid
          container
          columnSpacing={{ xs: 0, md: 0, lg: 12, xl: 20 }}
          rowSpacing={{ xs: "40px", md: 0 }}
          alignItems="center"
          sx={{
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            alignItems="center"
            justifyContent="flex-start"
            display="flex"
            sx={{
              maxWidth: { sm: "100%", lg: "50%" },
              alignItems: { sm: "end", lg: "unset" },
            }}
          >
            <Tooltip title="Version @@Version@@">
              <Box
                component="img"
                alt="Aura Blockchain Consortium Logo"
                src={AuraBcLogo}
              ></Box>
            </Tooltip>

            <Grid
              item
              xs={12}
              lg={4}
              alignItems="center"
              justifyContent="center"
              mt="20px"
              sx={{
                display: { xs: "none", md: "block", lg: "none" },
                marginLeft: { sm: "50px", lg: "0" },
                textAlign: { md: "center" },
              }}
            ></Grid>
          </Grid>
          <Grid
            item
            xs={12}
            lg={4}
            alignItems="center"
            justifyContent="center"
            sx={{
              display: { xs: "block", md: "none", lg: "flex" },
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Typography color={"greyBlue.dark"} variant={"body11"}>
              © 2022 Aura Blockchain Consortium. All rights reserved. Rue Baylon
              2bis, 1227 Carouge, Switzerland
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexDirection="column"
            sx={{
              gap: { xs: "20px", lg: "10px" },
              alignItems: { xs: "center", md: "center", lg: "flex-end" },
            }}
          >
            <LinksUserSupportTermsPolicy />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
