import mtmApiInstance from "utils/mtmApi";
import { BrandAttributes } from "utils/types";
const isLocal = process.env.REACT_APP_LOCAL_MODE;

interface fetchBrandsParams {
  page?: number;
  limit?: number;
  min_collection_number?: number;
}

export const fetchBrands = async (options?: fetchBrandsParams) => {
  const { page, limit, min_collection_number } = options || {};
  const params: fetchBrandsParams = {};
  if (page) {
    params.page = page;
  }
  if (limit) {
    params.limit = limit;
  }
  if (min_collection_number) {
    params.min_collection_number = min_collection_number;
  }
  const {
    status,
    data,
  }: {
    status: number;
    data: {
      data: BrandAttributes[];
      count: number;
      numberOfPages: number;
    };
  } = await mtmApiInstance.get("/api/brands/", { params });
  return {
    data: data.data,
    status,
  };
};

export const fetchBrandById = async (brandId: string | number) => {
  const { status, data }: { status: number; data: BrandAttributes } =
    await mtmApiInstance.get(`/api/brands/${brandId}`);
  return {
    data,
    status,
  };
};

export const fetchAllBrands = async () => {
  const {
    status,
    data,
  }: {
    status: number;
    data: BrandAttributes[];
  } = await mtmApiInstance.get(`/api/brands`);
  return {
    data: data,
    status,
  };
};
