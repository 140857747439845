import SmartContractLink from "components/SmartContracts/SmartContractLink";
import metamaskLogo from "../../assets/images/metamask-logo.png";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchBrandById } from "services/brandsService";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  CardMedia,
  Card,
  CardContent,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import parse from "html-react-parser";
import {
  fetchAllTokenByIdScAirDrop,
  fetchBlockchainListAirDrop,
  fetchOneSmartcontratAirDrop,
  mintTokenAirDrop
} from "services/airdrop/airdropService";
import Modal from "@mui/material/Modal";
import { isEthereumPublicKey } from "utils/checkWallet";
import AlertMessage from "components/Airdrop/AlertMessage";
import { styleModal } from "components/Airdrop/Style/style";
import Loader from "components/UI/Loader";
import {
  verifyMintStorage,
  insertInLocalStorage,
  encryptWithAES,
} from "services/airdrop/StorageService";
import {
  connectMetaMask,
  getCurrentWalletConnected,
} from "services/airdrop/metamaskService";
const { REACT_APP_IS_DEBUG } = process.env;

const Airdrop0 = () => {
  const { smartcontractId } = useParams();
  const [singleSmartContract, setSingleSmartContract] = useState<any>("");
  const [scType, setScType] = useState("");
  const [tokens, setTokens] = useState<any>("");
  const [brand, setBrand] = useState<any>("");
  const [tokenModal, setTokenModal] = useState<any>("");

  const [message, setMessage] = useState("");
  const [status, setStatus] = useState<any>();

  const [blockchain, setBlockchain] = useState([]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  useEffect(() => {
    (async () => {
      getSingleSC();
      const blockchainData: any = await fetchBlockchainListAirDrop();
      setBlockchain(blockchainData.data.blockchains);
    })();
  }, []);

  //Find blockchain by id
  const getBlockchainInfo = (idBc: any) => {
    const oneBc: any = blockchain.filter((item: any) => item.Id === idBc);
    return <>{oneBc[0]?.Name}</>;
  };

  const getSingleSC = async () => {
    const singleSc: any = await fetchOneSmartcontratAirDrop(smartcontractId);
    setSingleSmartContract(singleSc.data);

    //Smartcontract type
    const customJsonData = JSON.parse(singleSc.data?.CustomJsonData!);
    const scTypeName = customJsonData?.tokenType;

    //Call function get token
    getSmartcontractTokens();
    setScType(scTypeName);
    //Brand info by id Brand in SmartContract -> BrandId
    const brand: any = await fetchBrandById(singleSc.data.BrandId);
    setBrand(brand.data);
  };

  //Get token on smarctcontract
  const getSmartcontractTokens = async () => {
    //Token list
    const resGetSmartcontractTokens = await fetchAllTokenByIdScAirDrop(
      smartcontractId
    );
    const tokenList: any = resGetSmartcontractTokens.tokens.data;
    setTokens(tokenList);
  };

  //Find custom jsonData element
  const getCustomJsonDataList = () => {
    try {
      const attributes: any = JSON.parse(singleSmartContract.CustomJsonData);
      if (attributes && scType === "AuraSaaS") {
        let attributeHtml = "<ul class='attributes-tab'>";
        for (let attr of Object.getOwnPropertyNames(attributes)) {
          let isAuraSaasConfig = "";
          if (attr === "AuraSaasConfig") {
            isAuraSaasConfig = 'class="jsonConfig"';
          }
          attributeHtml +=
            "<li " +
            isAuraSaasConfig +
            ">" +
            attr +
            " : " +
            attributes[attr] +
            "</li>";
        }
        attributeHtml += "</ul>";
        return attributeHtml;
      } else {
        return "";
      }
    } catch (err) {
      return "";
    }
  };

  //--------------------------------------TOKEN--------------------------------
  const [open, setOpen] = useState(false);
  //Form useState
  const [state, setState] = useState({
    walletAddr: "",
  });
  const [tokenId, setTokenId] = useState("");
  const [messageMint, setMessageMint] = useState("");
  const [formValidator, setFormValidator] = useState(false);
  const [walletAdressMessage, setWalletAddressMessage] = useState("");
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showAlertMessage, setShowAlertMessage] = useState<boolean>(false);
  const [colorText, setColorText] = useState("");

  //Open modal
  const openModalToken = async (token: any) => {
    await connectMetaMask();
    setOpen(true);
    setTokenModal(token);
    setTokenId(token.Id);
  };
  //Close modal
  const handleClose = () => {
    setOpen(false);
    setShowLoader(false);
  };

  function handleInputChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (name === "walletAddr") {
      if (value === "") {
        setFormValidator(false);
        setWalletAddressMessage("Wallet adress cannot be empty");
      } else {
        setMessage("");
        if (isEthereumPublicKey(value)) {
          setFormValidator(true);
          setWalletAddressMessage("");
        } else {
          setFormValidator(false);
          setWalletAddressMessage("Wrong format for your wallet adress");
        }
      }
    }
    setState({
      ...state,
      [name]: value,
    });
  }

  const handleSubmit = async () => {
    verifyMint();
  };

  const verifyMint = async () => {
    const resultMint: any = await verifyMintStorage(state.walletAddr, "minted");
    switch (resultMint.code) {
      case 1:
        //Mint KO time < 24 hours
        setMessage(resultMint.reason);
        setStatus("error");
        setState({
          walletAddr: "",
        });
        break;
      case 2:
        //Mint OK time > 24 hours
        mint();
        break;
      case 3:
        mint();
        //Mint OK not exist in local storage
        break;
      case 4:
        mint();
        break;
      default:
        console.log("Default Mint Case -- do nothing");
    }
  };

  const mint = async () => {
    if (state.walletAddr !== "") {
      const data = {
        walletAddress: state.walletAddr,
        contractAddress: singleSmartContract.BcAddress,
        IdToken: tokenId,
      };
      setFormValidator(false);
      setShowLoader(true);
      const minted = await mintTokenAirDrop(data);
      if (minted.data.created) {
        setMessageMint("Token #" + tokenId + " successfully minted");
        handleClose();
        setState({ walletAddr: "" });

        //SET DS LOCALSTORAGE!
        await insertInLocalStorage(data.walletAddress, "minted");

        //Refresh token list
        getSmartcontractTokens();
        setShowAlertMessage(true);
      } else {
        setMessageMint(
          "Error while minting Token #" +
            tokenId +
            ", please contact the support Team"
        );
        setColorText("red");
        setShowAlertMessage(true);
      }
    } else {
      setMessageMint("Error minted token");
      setWalletAddressMessage("Wallet adress cannot be empty ");
    }
  };
  //-------------------------------------END TOKEN-----------------------------
  //-------------------------------CONNECT TO METAMASK ------------------------
  const [walletAddress, setWalletAddress] = useState("");
  const [statusConnect, setStatusConnect] = useState<any>("");
  const [ethereum, setEthereum] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      addWalletListener();
    })();
  }, []);

  const connectToMetamask = async () => {
    const walletResponse: any = await connectMetaMask();
    setStatusConnect(walletResponse.status);
    setWalletAddress(walletResponse.address);
    //Check wallet adress
    checkAdress(walletResponse.address);
    if (walletResponse.address) {
      setState({
        walletAddr: walletResponse.address,
      });
    }
  };

  //Check validate adresse
  const checkAdress = (walletAdress: string) => {
    if (isEthereumPublicKey(walletAdress)) {
      setFormValidator(true);
      setWalletAddressMessage("");
    } else {
      setFormValidator(false);
      setWalletAddressMessage("Wrong format for your wallet adress");
    }
  };

  //Change account
  const addWalletListener = () => {
    if (window.ethereum) {
      setEthereum(true);
      window.ethereum.on("accountsChanged", (accounts: any) => {
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
          setStatusConnect("Connected");
          if (REACT_APP_IS_DEBUG === "true") {
            console.log("Account change : ", accounts[0]);
          }
          //Check value adress
          checkAdress(accounts[0].trim());
          if (accounts[0].length > 0) {
            setState({
              walletAddr: accounts[0].trim(),
            });
          }
        } else {
          setWalletAddress("");
          setStatusConnect("🦊 Login to Metamask.");
        }
      });
    } else {
      setEthereum(false);
      setStatusConnect(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, an Ethereum wallet extension on your
            browser.
          </a>
        </p>
      );
    }
  };
  //-------------------------------END CONNECT TO METAMASK --------------------

  return (
    <Container
      sx={{
        mt: { xs: "40px", lg: "80px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      maxWidth="lg"
    >
      {singleSmartContract !== null ? (
        <>
          {
            //Alert message
            showAlertMessage ? (
              <AlertMessage
                opened={showAlertMessage}
                content={"Congratulation! The token is now Yours !"}
                colorText={colorText}
              ></AlertMessage>
            ) : (
              ""
            )
          }

          <Box>
            <Typography
              textAlign="center"
              variant="h3"
              color="white.main"
              sx={{
                fontWeight: 200,
              }}
            >
              {singleSmartContract.Name}
            </Typography>
            <Grid container spacing={2} padding={1}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box>
                  <Typography
                    textAlign="center"
                    variant="body14"
                    color="white.main"
                    sx={{
                      maxWidth: {
                        xs: "280px",
                        sm: "80%",
                        md: "550px",
                        lg: "760px",
                      },
                      fontWeight: 200,
                    }}
                  >
                    Description: {singleSmartContract.Description}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="body16"
                    sx={{
                      cursor: "pointer",
                      color: "white.main",
                      fontWeight: 400,
                    }}
                  >
                    Blockchain :{" "}
                    {getBlockchainInfo(singleSmartContract.BlockchainId)}
                  </Typography>
                </Box>

                {singleSmartContract.TokenCollectionId !== null ? (
                  <Box>
                    <Typography
                      variant="body16"
                      sx={{
                        cursor: "pointer",
                        color: "white.main",
                        fontWeight: 400,
                      }}
                    >
                      Token ID : {singleSmartContract.TokenCollectionId}
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}

                {singleSmartContract.WalletId !== null ? (
                  <Box>
                    <Typography
                      variant="body16"
                      sx={{
                        cursor: "pointer",
                        color: "white.main",
                        fontWeight: 400,
                      }}
                    >
                      Wallet ID : {singleSmartContract.WalletId}
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}

                <Box>
                  <Typography
                    variant="body16"
                    sx={{
                      cursor: "pointer",
                      color: "white.main",
                      fontWeight: 400,
                    }}
                  >
                    Brand : {brand.Name} ({brand.Id})
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="body16"
                    sx={{
                      cursor: "pointer",
                      color: "white.main",
                      fontWeight: 400,
                    }}
                  >
                    Adresse : {singleSmartContract.BcAddress}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="body16"
                    sx={{
                      cursor: "pointer",
                      color: "white.main",
                      fontWeight: 400,
                    }}
                  >
                    Smartcontract type : {scType}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="body16"
                    sx={{
                      cursor: "pointer",
                      color: "white.main",
                      fontWeight: 400,
                    }}
                  >
                    Wallet id : {singleSmartContract.WalletId}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="body16"
                    sx={{
                      cursor: "pointer",
                      color: "white.main",
                      fontWeight: 400,
                    }}
                  >
                    Status :{" "}
                    {singleSmartContract.State == 3 ? (
                      <span>⛓</span>
                    ) : (
                      <span>❌</span>
                    )}
                  </Typography>
                </Box>

                {scType === "AuraSaaS" ? (
                  <Box className="attribute-token">
                    <Typography
                      variant="body16"
                      sx={{
                        cursor: "pointer",
                        color: "white.main",
                        fontWeight: 400,
                      }}
                    >
                      Custom json Data : {parse(getCustomJsonDataList())}
                    </Typography>
                  </Box>
                ) : (
                  ""
                )}

                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "grid",
                      gridAutoColumns: "1fr",
                      gap: 1,
                    }}
                  >
                    <Box sx={{ gridRow: "1", gridColumn: "span 2" }}>
                      {singleSmartContract.State === 3 && (
                        <SmartContractLink
                          blockchainId={singleSmartContract.BlockchainId}
                          adress={singleSmartContract.BcAddress}
                        />
                      )}
                    </Box>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <CardMedia
                  component="img"
                  height="200"
                  image="https://via.placeholder.com/150"
                  alt="Paella dish"
                />
              </Grid>
            </Grid>
          </Box>

          {tokens.length > 0 ? (
            <Box marginTop={10}>
              <Typography
                textAlign="center"
                variant="h3"
                color="white.main"
                sx={{
                  fontWeight: "bold",
                }}
              >
                Choose the Token {scType} you want
              </Typography>
              <Grid container spacing={2}>
                {tokens.map((token: any, index: any) => {
                  if (token.State === 0) {
                    return (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <Item className="cardPad">
                          <Typography
                            gutterBottom
                            variant="h5"
                            component="div"
                            style={{
                              position: "absolute",
                            }}
                            className="token-state"
                          >
                            {token.State === 1 ? (
                              <span>⛓</span>
                            ) : (
                              <span>❌</span>
                            )}
                          </Typography>
                          <Card
                            sx={{ maxWidth: "auto" }}
                            onClick={() => openModalToken(token)}
                          >
                            <CardMedia
                              component="img"
                              height="140"
                              image={
                                token.ImgBucketPath.slice(0, 5) !== "https"
                                  ? "https://via.placeholder.com/150"
                                  : token.ImgBucketPath
                              }
                              alt={token.Title}
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                {token.Title}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {token.Description.slice(0, 100)}...
                              </Typography>
                            </CardContent>
                          </Card>
                        </Item>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Box>
          ) : (
            <Box>
              <Typography
                textAlign="center"
                variant="h3"
                color="white.main"
                sx={{
                  maxWidth: {
                    xs: "280px",
                    sm: "50%",
                    md: "340px",
                    lg: "1080px",
                  },
                  fontWeight: 200,
                  marginTop: 5,
                }}
              >
                No Tokens found
              </Typography>
            </Box>
          )}

          {tokenModal !== "" ? (
            <Modal
              open={open}
              onClose={handleClose}
              className="mymodal"
              aria-labelledby="parent-modal-title"
              aria-describedby="parent-modal-description"
            >
              <Box sx={{ ...styleModal, width: "auto" }}>
                <p style={{ textAlign: "right" }}>
                  <Button className="btn-add" onClick={handleClose}>
                    Close
                  </Button>
                </p>
                <h2 id="parent-modal-title" className="parent-modal-title">
                  Mint token #{tokenModal.Id}
                </h2>
                <Box>
                  <Card sx={{ maxWidth: "auto" }}>
                    <CardMedia
                      component="img"
                      className="pict-modal"
                      image={
                        tokenModal.ImgBucketPath.slice(0, 5) !== "https"
                          ? "https://via.placeholder.com/150"
                          : tokenModal.ImgBucketPath
                      }
                      alt={tokenModal.Title}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {tokenModal.Title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {tokenModal.Description.slice(0, 100)}...
                      </Typography>
                      <Box>
                        <h3
                          id="parent-modal-title"
                          className="parent-modal-title"
                        >
                          Mint token
                        </h3>
                        {message && <Alert severity={status}>{message}</Alert>}
                        <form>
                          <TextField
                            type="text"
                            error={!formValidator && walletAdressMessage !== ""}
                            helperText={walletAdressMessage}
                            fullWidth
                            sx={{ m: 1 }}
                            id="outlined-basic"
                            label="my Wallet Addres"
                            variant="outlined"
                            name="walletAddr"
                            value={state.walletAddr}
                            onChange={handleInputChange}
                            required
                          />
                          {ethereum ? (
                            <img
                              src={metamaskLogo}
                              alt="Metamask"
                              onClick={connectToMetamask}
                            />
                          ) : (
                            ""
                          )}
                          {statusConnect}
                          {walletAddress.length > 0
                            ? "You are connected: " +
                              String(walletAddress).substring(0, 6) +
                              "..." +
                              String(walletAddress).substring(38)
                            : ""}
                          <p style={{ textAlign: "center" }}>
                            <Button
                              disabled={!formValidator}
                              variant="outlined"
                              onClick={handleSubmit}
                            >
                              Mint
                              {showLoader === true ? (
                                <Loader text="Mint loading..."></Loader>
                              ) : (
                                ""
                              )}
                            </Button>
                          </p>
                        </form>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            </Modal>
          ) : (
            ""
          )}
        </>
      ) : (
        <Typography variant="h1">
          <Alert severity="info">Smartcontract not found...</Alert>
        </Typography>
      )}
    </Container>
  );
};

export default Airdrop0;
