import axios from "axios";
const { REACT_APP_MTM_API_URL, NODE_ENV } = process.env;

const createApiInstance = (options = {}) => {
  return axios.create({
    baseURL:
      NODE_ENV === "development"
        ? "http://localhost:3000/"
        : REACT_APP_MTM_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const mtmApiInstance = createApiInstance();

export default mtmApiInstance;
