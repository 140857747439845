import metamaskLogo from "../../assets/images/metamask-logo.png";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Container,
  Typography,
  CardMedia,
  Card,
  CardContent,
  TextField,
} from "@mui/material";
import parse from "html-react-parser";
import {
  createWallet,
  fetchBlockchainListAirDrop,
  fetchOneSmartcontractAirdropV1,
  fetchOneTokenByIdAirDrop,
  mintTokenAirDrop
} from "services/airdrop/airdropService";
import Modal from "@mui/material/Modal";
import { isEthereumPublicKey } from "utils/checkWallet";
import AlertMessage from "components/Airdrop/AlertMessage";
import { styleModal } from "components/Airdrop/Style/style";
import Loader from "components/UI/Loader";
import {
  encryptWithAES,
  decryptWithAES,
  insertInLocalStorage,
  verifyMintStorage,
} from "services/airdrop/StorageService";
import { connectMetaMask } from "services/airdrop/metamaskService";
import SmartContractLink from "components/SmartContracts/SmartContractLink";
const { REACT_APP_IS_DEBUG } = process.env;

const Claim = () => {
  const { walletAdrs } = useParams();
  const [singleSmartContract, setSingleSmartContract] = useState<any>("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState<any>();
  const [blockchain, setBlockchain] = useState([]);
  const [tokenDetail, setTokenDetail] = useState<any>("");
  const [bcAdress, setBcAdress] = useState("");
  const [blockchainId, setBlockchainId] = useState<any>();
  const [tokenId, setTokenId] = useState<Number>(0);

  useEffect(() => {
    (async () => {
      try {
        console.log("IS_DEBUG : ", REACT_APP_IS_DEBUG);
        await Promise.all([getBlockChainList(), getSingleSC()]);
      } catch (e) {
        if (REACT_APP_IS_DEBUG === "true") {
          console.log("e", e);
        }
      }
    })();
  }, []);

  const getSingleSC = async () => {
    setSingleSmartContract(null);
    const url: string = encryptWithAES(
      "0x9641eCB8390c7691e229b40dd3fa42b6C1B37Fd7__2__11270"
    );
    if (REACT_APP_IS_DEBUG === "true") {
      console.log("URL Crypted : ", url);
    }

    //Traitement params
    const urlDecrypt = decryptWithAES(url!.toString());
    if (REACT_APP_IS_DEBUG === "true") {
      console.log("URL Decrypted : ", urlDecrypt);
    }
    const urlDecryp = urlDecrypt.split("__");
    if (REACT_APP_IS_DEBUG === "true") {
      console.log("URL Decrypted (split): ", urlDecryp);
    }
    const scAdress = urlDecryp[0];
    let bcIdent = urlDecryp[1];
    let tokenId = urlDecryp[2];
    if (REACT_APP_IS_DEBUG === "true") {
      console.log("SC adress : ", scAdress);
      console.log("Blockchain id : ", bcIdent);
      console.log("Token ID : ", tokenId);
    }

    setTokenId(parseInt(tokenId));
    setBcAdress(scAdress);
    setBlockchainId(bcIdent);
    const blockchainData: any = await fetchBlockchainListAirDrop();
    const oneBc: any = blockchainData.data.blockchains.filter((item: any) => {
      return parseInt(item.Id) === parseInt(bcIdent);
    });
    const bcName = oneBc[0]?.Name;
    //REACT_APP_IS_DEBUG === "true" ? console.log("BC name : ", bcName) : ""

    const singleSc: any = await fetchOneSmartcontractAirdropV1(
      scAdress,
      bcName
    );
    setSingleSmartContract(singleSc.data.SmartContractDetail);

    await getSingleToken(parseInt(tokenId));
  };

  //Get BlockChain List
  const getBlockChainList = async () => {
    const blockchainData: any = await fetchBlockchainListAirDrop();
    await setBlockchain(blockchainData.data.blockchains);
    if (REACT_APP_IS_DEBUG === "true")
      console.log(
        "blockchainData",
        JSON.stringify(blockchainData.data.blockchains)
      );
  };

  //Get token detail
  const getSingleToken = async (tokeId: any) => {
    setShowLoader(true);
    const resToken = await fetchOneTokenByIdAirDrop(tokeId);
    if (REACT_APP_IS_DEBUG === "true") {
      console.log("Token detail : ", resToken);
    }

    const token: any = resToken.data;
    if (resToken.status === 200) {
      setShowLoader(false);
    }
    setTokenDetail(token);
  };

  //--------------------------------------TOKEN--------------------------------
  const [open, setOpen] = useState(false);
  //Form useState
  const [state, setState] = useState({
    walletAddr: "",
  });
  const [messageMint, setMessageMint] = useState("");
  const [formValidator, setFormValidator] = useState(false);
  const [walletAdressMessage, setWalletAddressMessage] = useState("");
  const [showLoader, setShowLoader] = useState<boolean>(false);

  function handleInputChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (name === "walletAddr") {
      if (value === "") {
        setMessage("");
        setFormValidator(false);
        setWalletAddressMessage("Wallet adress cannot be empty");
      } else {
        setMessage("");
        if (isEthereumPublicKey(value)) {
          setFormValidator(true);
          setWalletAddressMessage("");
        } else {
          setFormValidator(false);
          setWalletAddressMessage("Wrong format for your wallet adress");
        }
      }
    }
    setState({
      ...state,
      [name]: value,
    });
  }
  //-------------------------------------END TOKEN-----------------------------
  //-------------------------------CONNECT TO METAMASK ------------------------
  const [walletAddress, setWalletAddress] = useState("");
  const [statusConnect, setStatusConnect] = useState<any>("");
  const [ethereum, setEthereum] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      addWalletListener();
    })();
  }, []);

  const connectToMetamask = async () => {
    const walletResponse: any = await connectMetaMask;
    setStatusConnect(walletResponse.status);
    setWalletAddress(walletResponse.address);
    //Check wallet adress
    checkAdress(walletResponse.address);
    if (walletResponse.address) {
      setState({
        walletAddr: walletResponse.address,
      });
    }
  };

  //Check validate adresse
  const checkAdress = (walletAdress: string) => {
    if (isEthereumPublicKey(walletAdress)) {
      setFormValidator(true);
      setWalletAddressMessage("");
    } else {
      setFormValidator(false);
      setWalletAddressMessage("Wrong format for your wallet adress");
    }
  };

  //Change account
  const addWalletListener = () => {
    if (window.ethereum) {
      setEthereum(true);
      window.ethereum.on("accountsChanged", (accounts: any) => {
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
          setStatusConnect("Connected");
          if (REACT_APP_IS_DEBUG === "true") {
            console.log("Account change : ", accounts[0]);
          }

          //Check value adress
          checkAdress(accounts[0].trim());
          if (accounts[0].length > 0) {
            setState({
              walletAddr: accounts[0].trim(),
            });
          }
        } else {
          setWalletAddress("");
          setStatusConnect("🦊 Login to Metamask.");
        }
      });
    } else {
      setEthereum(false);
      setStatusConnect(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, an Ethereum wallet extension on your
            browser.
          </a>
        </p>
      );
    }
  };
  //-------------------------------END CONNECT TO METAMASK --------------------
  //-------------------------------START CLAIM -------------------------------
  const [showAlertMessage, setShowAlertMessage] = useState<boolean>(false);
  const [contentMessage, setContentMessage] = useState("");
  const [colorText, setColorText] = useState("");
  const [showForm, setShowForm] = useState(false);
  const checkLink = (link: string) => {
    if (link) {
      const linkIpfs = link.replace("ipfs://", "http://ipfs.io/ipfs/");
      return (
        <>
          <Button
            href={`${linkIpfs}`}
            target="_blank"
            variant="outlined"
            style={{ marginTop: "-2px", justifyContent: "center" }}
          >
            IPFS
          </Button>
        </>
      );
    } else {
      return "";
    }
  };

  const getAttributes = () => {
    try {
      const attributes: any = JSON.parse(tokenDetail.Attributes);

      if (attributes) {
        let attributeHtml = "<ul class='attributes-tab'>";
        for (let attr of Object.getOwnPropertyNames(attributes)) {
          attributeHtml += "<li>" + attr + " : " + attributes[attr] + "</li>";
        }
        attributeHtml += "</ul>";
        return attributeHtml;
      } else {
        return "";
      }
    } catch (err) {
      return "";
    }
  };

  const handleSubmit = async () => {
    verifyClaim();
  };

  const verifyClaim = async () => {
    const resultClaim: any = await verifyMintStorage(
      state.walletAddr,
      "claimed"
    );
    if (REACT_APP_IS_DEBUG === "true") {
      console.log("Resultat claimed : ", resultClaim);
    }

    switch (resultClaim.code) {
      case 1:
        //Claim KO time < 24 hours
        setMessage(resultClaim.reason);
        setStatus("error");
        setState({
          walletAddr: "",
        });
        setFormValidator(false);
        break;
      case 2:
        //Claim OK time > 24 hours
        claimed();
        break;
      case 3:
        claimed();
        //Claim OK not exist in local storage
        break;
      case 4:
        claimed();
        break;
      default:
        if (REACT_APP_IS_DEBUG === "true") {
          console.log("Default Claim Case -- do nothing");
        }
    }
  };

  const claimed = async () => {
    if (state.walletAddr !== "") {
      const data = {
        walletAddress: state.walletAddr,
        contractAddress: bcAdress,
        IdToken: tokenDetail.Id,
      };
      setFormValidator(false);
      setShowLoader(true);
      const minted = await mintTokenAirDrop(data);
      if (minted.data.created) {
        setMessageMint("Token #" + tokenId + " successfully claimed");
        setState({ walletAddr: "" });

        //SET DS LOCALSTORAGE!
        await insertInLocalStorage(data.walletAddress, "claimed");
        //Hide loader
        setShowLoader(false);
        //Refresh token list
        getSingleToken(parseInt(tokenDetail.Id));
        setContentMessage("Congratulation! Token claimed !");
        setShowAlertMessage(true);
        setShowForm(false);
      } else {
        setMessageMint(
          "Error while claimed Token #" +
            tokenId +
            ", please contact the support Team"
        );
        setColorText("red");
        setShowAlertMessage(true);
      }
    } else {
      setMessageMint("Error claimed token");
      setWalletAddressMessage("Wallet adress cannot be empty ");
    }
  };
  //-------------------------------END CLAIM -------------------------------
  //-------------------------------START CREATED WALLET --------------------
  //State form create wallet
  const [stateAccount, setStateAccount] = useState({
    email: "",
  });
  const [formValidatorAccount, setFormValidatorAccount] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [disableCreateWallet, setDisableCreateWallet] = useState<boolean>(true);

  //Open modal
  const openModalToken = async () => {
    setMessage("");
    setOpen(true);
  };
  //Close modal
  const handleClose = async () => {
    setOpen(false);
    setShowLoader(false);
  };

  const emailValidator = (email: string) => {
    if (
      email
        .trim()
        .match(
          /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/
        ) == null
    ) {
      return false;
    } else {
      return true;
    }
  };
  function handleInputChangeFormAccount(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (name === "email") {
      if (value === "") {
        setFormValidatorAccount(false);
        setEmailErrorMessage("Email adress cannot be empty");
      } else {
        //Check email
        if (!emailValidator(value)) {
          setFormValidatorAccount(false);
          setEmailErrorMessage("Your email is not valid");
        } else {
          setFormValidatorAccount(true);
          setEmailErrorMessage("");
        }
      }
    }
    setStateAccount({
      ...stateAccount,
      [name]: value,
    });
  }

  const createWalletUser = async () => {
    setShowLoader(true);
    const data = {
      email: stateAccount.email,
      blockchainId: blockchainId,
    };
    const postWallet = await createWallet(data);
    if (REACT_APP_IS_DEBUG === "true") {
      console.log("Result Wallet post : ", postWallet);
    }

    if (
      postWallet.data.result === "true" &&
      isEthereumPublicKey(postWallet.data.publicKey)
    ) {
      setShowLoader(false);
      setMessage(postWallet.data.message + " : " + postWallet.data.publicKey);
      setStatus("success");
      setStateAccount({
        email: "",
      });
      setFormValidatorAccount(false);
      setState({
        walletAddr: postWallet.data.publicKey,
      });
      if (isEthereumPublicKey(postWallet.data.publicKey)) {
        setFormValidator(true);
        setWalletAddressMessage("");
      } else {
        setFormValidator(false);
        setWalletAddressMessage("Wrong format for your wallet adress");
      }
      handleClose();
      setShowAlertMessage(true);
      setContentMessage("Congratulation, you have now a new Wallet");
      setDisableCreateWallet(false);
    } else {
      setShowLoader(false);
      setMessage(postWallet.data.message);
      setStatus("error");
      setStateAccount({
        email: "",
      });
      setFormValidatorAccount(false);
    }
  };

  //-------------------------------END CREATED WALLET ----------------------
  return (
    <Container
      sx={{
        mt: { xs: "40px", lg: "80px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      maxWidth="lg"
    >
      {tokenDetail ? (
        <>
          {
            //Alert message
            showAlertMessage ? (
              <AlertMessage
                opened={showAlertMessage}
                content={contentMessage}
                colorText={colorText}
              ></AlertMessage>
            ) : (
              ""
            )
          }
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "inherit",
              marginTop: "50px",
            }}
            maxWidth="xl"
          >
            <Typography
              textAlign="center"
              variant="h1"
              color="white.main"
              sx={{
                maxWidth: { xs: "280px", sm: "50%", md: "340px", lg: "700px" },
                fontWeight: 400,
              }}
            >
              Token : {tokenDetail.Title}
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                zIndex: 2,
                gap: "20px",
                maxWidth: { md: "668px", lg: "924px", xl: "1144px" },
                width: { md: "668px", lg: "90%", xl: "1144px" },
              }}
              marginBottom={5}
            >
              <Box
                gap="20px"
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
              >
                <>
                  <Card
                    elevation={0}
                    sx={{
                      width: {
                        xs: "280px",
                        md: "274px",
                        lg: "261px",
                        xl: "271px",
                      },
                      borderRadius: "8px",
                      bgcolor: "background.light",
                      position: "relative",
                      transition: "0.3s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        transition: "0.3s",
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      sx={{
                        height: {
                          xs: "247px",
                          md: "240px",
                          lg: "231px",
                          xl: "240px",
                        },
                        width: "100%",
                        maxWidth: {
                          xs: "280px",
                          md: "274px",
                          lg: "261px",
                          xl: "271px",
                        },
                      }}
                      image={
                        tokenDetail.ImgBucketPath == null ||
                        tokenDetail.ImgBucketPath.slice(0, 5) !== "https"
                          ? "https://via.placeholder.com/150"
                          : tokenDetail.ImgBucketPath
                      }
                      alt={tokenDetail.Title}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        p: {
                          xs: "24px 20px 20px 20px",
                          md: "25px 20px 20px 20px",
                          lg: "20px",
                          xl: "22px 20px 20px 20px",
                        },
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          maxWidth: "230px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          mb: { xs: "7px", lg: "4px" },
                        }}
                        color="white.main"
                      >
                        {tokenDetail.Title}
                      </Typography>
                    </Box>
                  </Card>
                </>
              </Box>
            </Box>

            <Box
              className="token-box"
              sx={{
                width: "80%",
                height: "auto",
                bgcolor: "background.light",
                borderRadius: "10px 10px 0px 0px",
                color: "white",
                marginBottom: "40px",
                padding: "20px",
              }}
            >
              <Box>
                <Typography color="white.main" variant="h5">
                  ID : {tokenDetail.Id}
                </Typography>
              </Box>

              <Box>
                <Typography color="white.main" variant="h5">
                  Description : {tokenDetail.Description}
                </Typography>
              </Box>

              <Box>
                <Typography color="white.main" variant="h5">
                  Attributes :
                </Typography>
                <Box className="attribute-token">{parse(getAttributes())}</Box>
              </Box>

              <Box>
                <Typography color="white.main" variant="h5">
                  Mint date : {tokenDetail.MintDate}
                </Typography>
              </Box>

              <Box>
                <Typography color="white.main" variant="h5">
                  Sell value : {tokenDetail.SellValue}
                </Typography>
              </Box>

              <Box>
                {tokenDetail.ActualOwnerAddress !== null ? (
                  <Typography color="white.main" variant="h5">
                    Actual Owner Address : {tokenDetail.ActualOwnerAddress}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>

              <Box>
                {tokenDetail.TokenType !== null ? (
                  <Typography color="white.main" variant="h5">
                    Token type : {tokenDetail.TokenType}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>

              <Box>
                {tokenDetail.MetadataCid !== null ? (
                  <Typography color="white.main" variant="h5">
                    MetadataCid : {checkLink(tokenDetail.MetadataCid)}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>

              <Box>
                {tokenDetail.ImgCid !== null ? (
                  <Typography color="white.main" variant="h5">
                    ImgCid : {checkLink(tokenDetail.ImgCid)}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>

              <Box>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  color="white.main"
                >
                  Status :{" "}
                  {tokenDetail.State === 1 ? <span>⛓</span> : <span>❌</span>}
                </Typography>
              </Box>

              <div style={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "grid",
                    gridAutoColumns: "1fr",
                    gap: 1,
                  }}
                >
                  <Box sx={{ gridRow: "1", gridColumn: "span 2" }}>
                    {singleSmartContract ? (
                      <>
                        {singleSmartContract.State === 3 && (
                          <SmartContractLink
                            blockchainId={blockchainId}
                            adress={singleSmartContract?.BcAddress}
                          />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </div>
              {showForm ? (
                <>
                  {message && (
                    <Alert severity={status} className="alert-message">
                      {message}
                    </Alert>
                  )}
                  <form
                    style={{
                      backgroundColor: "white",
                      padding: 10,
                    }}
                  >
                    <TextField
                      type="text"
                      error={!formValidator && walletAdressMessage !== ""}
                      helperText={walletAdressMessage}
                      fullWidth
                      sx={{ m: 1 }}
                      id="outlined-basic"
                      label="my Wallet Addres"
                      variant="outlined"
                      name="walletAddr"
                      value={state.walletAddr}
                      onChange={handleInputChange}
                      required
                    />
                    {ethereum ? (
                      <img
                        src={metamaskLogo}
                        alt="Metamask"
                        onClick={connectToMetamask}
                      />
                    ) : (
                      ""
                    )}
                    {statusConnect}
                    {walletAddress.length > 0
                      ? "You are connected: " +
                        String(walletAddress).substring(0, 6) +
                        "..." +
                        String(walletAddress).substring(38)
                      : ""}
                    <p style={{ textAlign: "center" }}>
                      <Button
                        disabled={!formValidator}
                        variant="outlined"
                        onClick={handleSubmit}
                      >
                        Claim
                        {showLoader === true ? (
                          <Loader text="Claiming loading..."></Loader>
                        ) : (
                          ""
                        )}
                      </Button>
                      &nbsp;&nbsp;&nbsp;
                      <Button
                        disabled={!disableCreateWallet}
                        variant="outlined"
                        onClick={openModalToken}
                      >
                        I have no wallet
                      </Button>
                    </p>
                  </form>
                </>
              ) : (
                ""
              )}
              {tokenDetail.State === 0 ? (
                <p style={{ textAlign: "center" }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      if (!showForm) {
                        setShowForm(true);
                      } else {
                        setShowForm(false);
                      }
                    }}
                  >
                    Claim my Token
                  </Button>
                </p>
              ) : (
                <>
                  <Alert severity="warning">
                    This token is already claimed...
                  </Alert>
                </>
              )}
            </Box>
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            className="mymodal"
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <div className="modal-body">
              <Box sx={{ ...styleModal, width: "auto" }}>
                <p style={{ textAlign: "right" }}>
                  <Button className="btn-add" onClick={handleClose}>
                    Close
                  </Button>
                </p>
                <h2 id="parent-modal-title" className="parent-modal-title">
                  Create wallet
                </h2>
                <Box>
                  <Card sx={{ maxWidth: "auto" }}>
                    <CardContent>
                      <Box>
                        {message && <Alert severity={status}>{message}</Alert>}
                        <form>
                          <TextField
                            type="text"
                            error={
                              !formValidatorAccount && emailErrorMessage !== ""
                            }
                            helperText={emailErrorMessage}
                            fullWidth
                            sx={{ m: 1 }}
                            id="outlined-basic"
                            label="Your mail adress"
                            variant="outlined"
                            name="email"
                            value={stateAccount.email}
                            onChange={handleInputChangeFormAccount}
                            required
                          />
                          <p style={{ textAlign: "center" }}>
                            <Button
                              disabled={!formValidatorAccount}
                              variant="outlined"
                              onClick={createWalletUser}
                            >
                              Create my Wallet
                              {showLoader === true ? (
                                <Loader text="Creating loading..."></Loader>
                              ) : (
                                ""
                              )}
                            </Button>
                          </p>
                        </form>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            </div>
          </Modal>
        </>
      ) : (
        <Typography variant="h1">
          <Alert severity="info">
            {showLoader === true ? <Loader text="Find token..."></Loader> : ""}
          </Alert>
        </Typography>
      )}
    </Container>
  );
};

export default Claim;
