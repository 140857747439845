import Breadcrumbs, { BreadcrumbsProps } from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import { ReactComponent as HomeIcon } from "assets/icons/home.svg";
import { Link } from "react-router-dom";
import { FC } from "react";
import { styled } from "@mui/material/styles";

interface StyledBreadcrumbsProps {
  light: boolean;
}

const StyledBreadcrumbs = styled(Breadcrumbs, {
  shouldForwardProp: (prop) => prop !== "light",
})<BreadcrumbsProps & StyledBreadcrumbsProps>(({ theme, light }) => ({
  "&.MuiBreadcrumbs-root .MuiBreadcrumbs-ol .MuiBreadcrumbs-li": {
    display: "flex",
    "& svg path": {
      stroke: light
        ? theme?.palette?.greyBlue?.light
        : theme?.palette?.greyBlue?.dark,
    },
  },
}));

interface BC {
  text: string;
  link?: string;
}

const prepareBreadcrumbs = (breadcrumb: BC, key: number, light?: boolean) =>
  breadcrumb.link ? (
    <Link key={key} to="/">
      <Typography
        variant="body11"
        fontSize="10px"
        color={light ? "greyBlue.light" : "greyBlue.dark"}
      >
        {breadcrumb.text}
      </Typography>
    </Link>
  ) : (
    <Typography
      variant="body11"
      fontSize="10px"
      color={light ? "greyBlue.light" : "greyBlue.dark"}
      key={key}
    >
      {breadcrumb.text}
    </Typography>
  );

const BreadcrumbsNavigation: FC<{ breadcrumbs?: any[]; light?: boolean }> = ({
  breadcrumbs = [],
  light,
}) => {
  const DEFAULT_BREADCRUMBS = [
    <Link key="1" to="/">
      <HomeIcon />
    </Link>,
  ];
  return (
    <StyledBreadcrumbs
      light
      separator={
        <NavigateNextIcon
          fontSize="small"
          sx={{ color: light ? "greyBlue.light" : "greyBlue.dark" }}
        />
      }
      aria-label="breadcrumb"
    >
      {[
        ...DEFAULT_BREADCRUMBS,
        breadcrumbs.map((bc: BC, index: number) =>
          prepareBreadcrumbs(bc, index + DEFAULT_BREADCRUMBS.length + 1, light)
        ),
      ]}
    </StyledBreadcrumbs>
  );
};

export default BreadcrumbsNavigation;
