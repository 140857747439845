import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";

const AlertMessage = ({
  opened,
  content,
  colorText,
}: {
  opened: boolean;
  content: string;
  colorText?: string;
}) => {
  const [open, setOpen] = useState(opened);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Information</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          color={colorText || "black"}
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AlertMessage;
