import { AppBar, Grid } from "@mui/material";
import { Box } from "@mui/system";
import Logo from "assets/images/aura-world-logo.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";

type HeaderProps = {
  connected?: boolean;
};

const Header = ({ connected }: HeaderProps) => {
  useEffect(() => {
    /*
    if (window?.ethereum && window.localStorage.getItem("MadeConnection")) {
      connect();
    } else {
      useStore.setState({ gettingConnectionStatus: false });
    }*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppBar
      elevation={0}
      color="secondary"
      position="relative"
      sx={{
        height: { xs: "60px", lg: "80px" },
        p: { xs: "12px 20px", lg: "20px 50px" },
      }}
      data-testid="header-wrapper"
      className="bg-gradient"
    >
      {/* <Grid container>
        <Grid
          item
          xs={12}
          lg={12}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap="20px"
          py="8px"
        >
          <RightActions connected={connected} accountAddress={account} />
        </Grid>
      </Grid> */}
    </AppBar>
  );
};

export default Header;
