export const TOP_QUESTIONS = [1, 1, 2, 3, 4, 5, 5];

export type Question = {
  question: string;
  questionId: number;
  answerId: number;
  categoryId: number;
};

export const QUESTIONS: Question[] = [
  {
    question: "Why do I need a crypto wallet?",
    questionId: 1,
    answerId: 1,
    categoryId: 1,
  },
  {
    question: "How do I connect my wallet?",
    questionId: 2,
    answerId: 2,
    categoryId: 1,
  },
  {
    question: "How do I buy a NFT?",
    questionId: 3,
    answerId: 3,
    categoryId: 1,
  },
  {
    question: "How do I switch networks?",
    questionId: 4,
    answerId: 4,
    categoryId: 1,
  },
  {
    question: "What is WETH? How does it work?",
    questionId: 5,
    answerId: 5,
    categoryId: 1,
  },
  { question: "What is a NFT?", questionId: 1, answerId: 1, categoryId: 2 },
  {
    question: "What is NFT metadata?",
    questionId: 2,
    answerId: 2,
    categoryId: 2,
  },
  {
    question: "What is NFT minting?",
    questionId: 3,
    answerId: 3,
    categoryId: 2,
  },
  { question: "What are gas fees?", questionId: 4, answerId: 4, categoryId: 2 },
  { question: "What is IPFS?", questionId: 5, answerId: 5, categoryId: 2 },
];

export const QUESTION_CATEGORIES = [
  { title: "Getting started", categoryId: 1 },
  { title: "About NFTs", categoryId: 2 },
];
