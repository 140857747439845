
import crypto from 'crypto-js';
import { capitalize } from 'lodash';
const { REACT_APP_PASS_PHRASE, REACT_APP_IS_DEBUG } = process.env;
//Encryption
export const encryptWithAES = (data: string) => {
    const dataCrypted = crypto.AES.encrypt(data, ""+REACT_APP_PASS_PHRASE).toString();
    if(REACT_APP_IS_DEBUG === "true"){ console.log('encryptWithAES data received',dataCrypted) }
    return dataCrypted
}

//Decryption
export const decryptWithAES = (data: string) =>{
    if(REACT_APP_IS_DEBUG === "true"){ console.log('decryptWithAES data received',data) }
    const deData = crypto.AES.decrypt(data, REACT_APP_PASS_PHRASE!)
    const originalData = deData.toString(crypto.enc.Utf8)
    if(REACT_APP_IS_DEBUG === "true"){ 
        console.log('decryptWithAES data deData',deData)
        console.log('decryptWithAES originalData',originalData)
    }
    return originalData
}

export const getDataFromLocalStorage = (lsKey:string) =>{
    let timesTamp: any;
    timesTamp = localStorage.getItem(lsKey)   
    if(!timesTamp){
        return []
    }
    return JSON.parse(timesTamp)
}


export const capitalizeResponse = (message: string)=>{ 
    return capitalize(message)
}

export const verifyMintStorage = async (walletAddress: any, lsKey:string)=>{
    let result = {};
    const currentDate = new Date();
    const currentTimestamp = currentDate.getTime();             
    let walletAdrs = encryptWithAES(walletAddress.trim()+"__"+currentTimestamp);
  
    const walletnew = decryptWithAES(walletAdrs);
    let dataLS = walletnew.split("__")
    let walletData = dataLS[0]
    let listeMintLS = getDataFromLocalStorage(lsKey)
    if(listeMintLS.length !== 0){
        //Select wallet adress in ls
        const minted = listeMintLS.filter((item: any)=> decryptWithAES(item).split("__")[0] === walletData)
        if(minted.length !== 0){
            const mintedDescypteLS: any = decryptWithAES(minted[0])
            const wallet = mintedDescypteLS.split("__")
            let walletLS = wallet[0]
            let timestampLS = parseInt(wallet[1])
            //Calculate beetween
            let between = currentTimestamp - timestampLS
            let hoursDifference = Math.floor(between / (1000 * 60 * 60))
            if(REACT_APP_IS_DEBUG === "true"){  console.log("Hours between : ", hoursDifference , "hour(s)") }
            if(hoursDifference < 24) {
                result = {
                    status : "KO",
                    code: 1,
                    reason : "You can't do 2 "+ lsKey +" in a day with this wallet : "+walletLS+""
                }
            }else{
                result = {
                    status : "OK",
                    code: 2,
                    reason : capitalizeResponse(lsKey) + " successfuly"
                }
            }
        }else{
            result = {
                status : "OK",
                code: 3,
                reason : capitalizeResponse(lsKey)  + " successfuly" 
            }
        }
    }else{
        result = {
            status : "OK",
            code: 4,
            reason : lsKey.charAt(0).toUpperCase() + lsKey.slice(1) + " successfuly"
        }
    }
    return result
}

export const insertInLocalStorage = async (walletAddress: any, lsKey: string) =>{
    const currentDate = new Date();
    const currentTimestamp = currentDate.getTime();             
    let walletTimestamped = encryptWithAES(walletAddress.trim()+"__"+currentTimestamp)
    let newData = [];
    let listeMintLS = getDataFromLocalStorage(lsKey)
    if(listeMintLS.length !== 0){
        const minted = listeMintLS.filter((item: any)=> decryptWithAES(item).split("__")[0] === walletAddress)
        if(minted.length !== 0){
            listeMintLS.splice(minted, 1);
            listeMintLS.push(walletTimestamped)
            localStorage.setItem(lsKey, JSON.stringify(listeMintLS))
        }else{
            listeMintLS.push(walletTimestamped)
            localStorage.setItem(lsKey, JSON.stringify(listeMintLS))
        }
    }else{
        newData = listeMintLS.concat(walletTimestamped)
        localStorage.setItem(lsKey, JSON.stringify(newData))
    }
}