import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Typography, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Theme } from "@mui/material/styles";
import { ReactComponent as RestrictedAreaIcon } from "assets/icons/restricted-area.svg";
import { useEffect, useState } from "react";
import mtmApiInstance from "utils/mtmApi";

const StyledDialog = styled(Dialog)(({ theme }: { theme: Theme }) => ({
  padding: "20px",
  "& .MuiDialogContent-root": {
    padding: 0,
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "20px",
  },
  "& .MuiDialogActions-root": {
    padding: 0,
  },
}));

export const RestrictedAreaPopup = () => {
  const [open, setOpen] = useState(false);
  /*
  useEffect(() => {
    ;(async () => {
      try {
        await mtmApiInstance.get('/api/ping')
      } catch (error: any) {
        if (error.response?.status === 403 && error.response?.data?.blocked) {
          setOpen(true)
        }
        console.log(error);
      }
    })()
  }, [])
*/
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <StyledDialog
      data-testid="restricted-area-dialog"
      open={open}
      fullWidth={lg}
      PaperProps={{
        sx: {
          m: 0,
          pt: "60px",
          pb: "53px",
          px: "20px",
          maxWidth: "560px",
          borderRadius: "10px",
        },
      }}
    >
      <DialogContent data-testid="restricted-area-dialog-content">
        <RestrictedAreaIcon />
        <Typography variant="h3" textAlign="center">
          You are in a restricted area
        </Typography>
        <Typography variant="body14" textAlign="center">
          Aura NFT Marketplace is not available yet in your country
        </Typography>
      </DialogContent>
    </StyledDialog>
  );
};
