import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";

const ANSWERS: { [x: string]: JSX.Element } = {
  "cat1-a1": (
    <Box
      alignItems="flex-start"
      display="flex"
      flexDirection="column"
      gap="30px"
    >
      <Typography variant="body14">
        Besides allowing you to store, consult and trade your cryptocurrency, a{" "}
        <Typography
          variant="body14"
          color="lightBorealBlue.main"
          fontWeight="bold"
        >
          non-custodial web3 wallet
        </Typography>{" "}
        is needed to access and interact with all the NFT platforms and
        decentralized applications available on dedicated blockchain networks.
      </Typography>
    </Box>
  ),
  "cat1-a2": (
    <Box
      alignItems="flex-start"
      display="flex"
      flexDirection="column"
      gap="30px"
    >
      <Typography variant="body14">
        Connecting your wallet on the platform is done via the Authentication
        page. Ensure that your wallet browser extension or mobile app is opened
        and connected to either Ethereum or Polygon networks.
      </Typography>
    </Box>
  ),
  "cat2-a1": (
    <Box
      alignItems="flex-start"
      display="flex"
      flexDirection="column"
      gap="30px"
    >
      <Typography variant="body14">
        A non-fungible token (NFT) is a uniquely identifiable unit of data
        stored on a blockchain. NFTs have metadata attached to it and may be
        associated with digital content (media, artwork, collectible).
      </Typography>
    </Box>
  ),
  "cat2-a2": (
    <Box
      alignItems="flex-start"
      display="flex"
      flexDirection="column"
      gap="30px"
    >
      <Typography variant="body14">
        Metadata provides information about the NFT such as its name,
        description and attributes. Metadata is usually stored off-chain, for
        example on IPFS (decentralized storage solution). A link to the metadata
        storage in IPFS is set as an attribute of the NFT.
      </Typography>
    </Box>
  ),
  "cat2-a3": (
    <Box
      alignItems="flex-start"
      display="flex"
      flexDirection="column"
      gap="30px"
    >
      <Typography variant="body14">
        Minting refers to the process of creating a NFT on the blockchain. The
        preliminary step for minting is the deployment of a NFT smart contract.
        Then a ‘mint’ function is called to create the NFTs. Once the NFT is
        ‘minted’, it can be either sold on the primary market or transferred.
      </Typography>
    </Box>
  ),
  "cat2-a4": (
    <Box
      alignItems="flex-start"
      display="flex"
      flexDirection="column"
      gap="30px"
    >
      <Typography variant="body14">
        Gas fees are payments made by users to compensate for the computing
        energy required to process and validate a transaction on the Ethereum
        blockchain. The gas fees may be varying upon the native token current
        price and the network congestion.
      </Typography>
    </Box>
  ),
  "cat2-a5": (
    <Box
      alignItems="flex-start"
      display="flex"
      flexDirection="column"
      gap="30px"
    >
      <Typography variant="body14">
        The InterPlanetary File System (IPFS) is a protocol and peer-to-peer
        network for storing and sharing data in a distributed file system. As
        opposed to a centrally located server, IPFS is built around a
        decentralized system of user-operators who hold a portion of the overall
        data, creating a resilient system of file storage and sharing.
      </Typography>
    </Box>
  ),
};

interface IAnswerProps {
  answerId: string;
  goBack: () => void;
  question: string;
}

const Answer = ({ answerId, goBack, question }: IAnswerProps) => {
  return (
    <Box>
      <Box display="flex" gap="20px" alignItems="center" mb="40px">
        <IconButton
          onClick={goBack}
          sx={{
            "&:hover": {
              "& svg": {
                "& path": {
                  stroke: (theme) => theme.palette.lightBorealBlue[40],
                },
                "& circle": {
                  stroke: (theme) => theme.palette.lightBorealBlue[40],
                },
              },
            },
          }}
        >
          <BackIcon />
        </IconButton>
        <Typography variant="h3">{question}</Typography>
      </Box>
      <Box>
        {ANSWERS[answerId] || (
          <Typography variant="body16" color="inherit" sx={{ mt: "24px" }}>
            No questions for selected category
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Answer;
